var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.groups),function(item,i){return _c('div',{key:i,staticClass:"group",class:{active: _vm.activeIndex === i},on:{"click":function($event){_vm.activeIndex = i}}},[_c('div',{staticClass:"title"},[_c('fm-form',{attrs:{"inline":""}},[_c('fm-form-item',{attrs:{"label":"总剂数"}},[_c('fm-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1},model:{value:(item.timesNum),callback:function ($$v) {_vm.$set(item, "timesNum", $$v)},expression:"item.timesNum"}})],1),_c('fm-form-item',{attrs:{"label":"用药频率"}},[_c('fm-select',{attrs:{"clearable":"","filterable":"","placeholder":"请选择用药频率"},on:{"change":function($event){return item.setUsageFrequency()}},model:{value:(item.usageFrequency),callback:function ($$v) {_vm.$set(item, "usageFrequency", $$v)},expression:"item.usageFrequency"}},_vm._l((_vm.usageFrequency),function(t,i){return _c('fm-option',{key:i,attrs:{"value":t,"label":t}})}),1)],1)],1)],1),_c('div',{ref:"table",refInFor:true,staticClass:"group-table",attrs:{"xxx":'(' + String(_vm.tableHeight) + ')'}},[_c('Table',{attrs:{"height":_vm.tableHeight,"columns":_vm.columns,"data":item.list,"no-data-text":"暂无数据"},scopedSlots:_vm._u([{key:"mdinfo",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"md-name"},[_vm._v(_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineName : row.medichine.name))]),_c('div',{staticClass:"md-type"},[_vm._v(_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineManufacturer : row.medichine.manufacturer))])])}},{key:"volume",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('div',{},[_c('fm-input-number',{staticStyle:{"width":"80px"},attrs:{"size":"small","min":1},on:{"input":function (v) {_vm.$set(item.list[index], 'volume', v); _vm.$set(item.list[index], 'amount', (row.volume * row.price).toFixed(2))}},model:{value:(row.volume),callback:function ($$v) {_vm.$set(row, "volume", $$v)},expression:"row.volume"}})],1)}},{key:"amount",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.amount))])}},{key:"usage",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('fm-select',{staticStyle:{"width":"100%"},attrs:{"clearable":"","filterable":"","placeholder":"请选择用法"},on:{"change":function (v) { return _vm.$set(item.list[index], 'medicineUsage', v); }},model:{value:(row.medicineUsage),callback:function ($$v) {_vm.$set(row, "medicineUsage", $$v)},expression:"row.medicineUsage"}},_vm._l((_vm.usage),function(t,i){return _c('fm-option',{key:i,attrs:{"value":t,"label":t}})}),1)}},{key:"origin",fn:function(ref){
var row = ref.row;
return _c('origin',{attrs:{"data":row.origin}})}},{key:"action",fn:function(ref){
var index = ref.index;
return _c('div',{},[_c('Button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.delMedichine(index)}}},[_vm._v("删除")])],1)}}],null,true)})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }