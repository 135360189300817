<style lang="less" scoped>
  .types {
    padding: 10px;
    display: flex;
    & + .types {
      margin-top: 10px;
    }
  }
  .type {
    width: 25%;
    margin: 10px;
    font-size: 15px;
    cursor: pointer;
    border: 1px solid #EEE;
    background-color: #F8F8F8;
    color: #000;
    text-align: center;
    transition: all .3s;
    padding: 30px 20px;
    &:hover {
      color: #2F5BEA;
      background-color: #EEE;
    }
  }
</style>

<template>
  <div>
    <Divider orientation="left">诊所药房</Divider>
    <div class="types">
      <div class="type" @click="$emit('confirm', { medicineType: '中药颗粒袋装' })">袋装</div>
      <div class="type" @click="$emit('confirm', { medicineType: '中药饮片' })">饮片</div>
      <div class="type" @click="$emit('confirm', { medicineType: '中药颗粒瓶装' })">瓶装</div>
    </div>
    <Divider orientation="left">
      <div>
        <Select v-model="chooseShareMainBodyGroupId" placeholder="请选择共享药房">
          <Option v-for="item in shareMainBodyGroupList" :key="item.mainbodyId" :value="item.mainbodyId" :label="'共享药房 - ' + item.mainbodyLabel"></Option>
        </Select>
      </div>
    </Divider>
    <div class="types" v-if="chooseShareMainBodyGroupId">
      <div class="type" @click="$emit('confirm', {
        medicineType: '中药颗粒瓶装',
        shareGroupId: chooseShareMainBodyGroupId
      })">瓶装</div>
    </div>
    <div v-else>未配置共享药房</div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider, Select, Option } from 'view-design'
import { types } from '@/views/medicine/lib'
import { mainbodyMedicineGroupRequest } from '@/api'

Vue.component('Divider', Divider)
Vue.component('Select', Select)
Vue.component('Option', Option)

export default {
  data () {
    return {
      shareMainBodyGroupList: [],
      chooseShareMainBodyGroupId: null
    }
  },
  computed: {
    medicineTypes () {
      return types
    }
  },
  methods: {
    async loadShareMainBodyGroupList () {
      this.shareMainBodyGroupList = await mainbodyMedicineGroupRequest.getBuy({type: 'share'})
      this.chooseShareMainBodyGroupId = this.shareMainBodyGroupList.length ? this.shareMainBodyGroupList[0].mainbodyId : null
    }
  },
  mounted () {
    this.loadShareMainBodyGroupList()
  }
}
</script>
