<style lang="less" scoped>
  .type {
    border: 1px solid #DDD;
    padding: 10px;
    transition: all .3s;
    border-radius: 5px;
    & + .type {
      margin-top: 10px;
    }
    &.hide {
      border-color: transparent;
      border-bottom-color: #DDD;
      .items {
        display: none;
      }
    }
  }
  .type-name {
    font-size: 15px;
    color: #000;
  }
  .type-cate {
    background-color: rgba(46, 107, 229, .2);
    padding: 2px 5px;
    border-bottom-right-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    position: absolute;
    color: rgba(46, 107, 229, 1);
    left: 0;
    top: 0;
    z-index: 1;
  }
  .item {
    border: 1px solid #eee;
    background-color: #F8F8F8;
    padding: 5px;
    position: relative;
    margin-top: 10px;
  }
  .row-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .manufacturer {
    color: #000;
    margin-bottom: 5px;
    font-weight: 800;
  }
  .packing {
    color: #999;
  }
  .price {
    color: red;
  }
  .medicine-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    .medicine {
      padding: 5px;
      box-sizing: border-box;
      width: 20%;
      .wrap {
        box-sizing: border-box;
        transition: all .3s;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        background-color: #F1F1F1;
      }
      &.active, &:hover {
        .wrap {
          background-color: rgba(46, 107, 229, .1);
        }
      }
      .action {
        margin-top: 5px;
        .btn {
          cursor: pointer;
        }
      }
    }
  }
  .type-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .type-medicine {
    color: #000;
    font-size: 15px;
    & > * + * {
      margin-left: 10px;
    }
    .btn {
      color:#999;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        color: rgba(46, 107, 229, 1);
      }
    }
  }
  .type-list {
    max-height: 60vh;
    overflow: auto;
  }
  .block-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
</style>

<template>
  <div clas="call-medicine">
    <div class="type-list scrollbar">
      <div class="type" v-for="item in dataList" :key="item.id" :class="{
        hide: selectedList[item.id]
      }">
        <div class="type-data">
          <div class="type-name">{{item.medicineTypeName}} - {{item.useOnce}}{{item.unit}}</div>
          <div class="type-medicine" v-if="selectedList[item.id]">
            <template v-if="selectedList[item.id].mainbodyMedicine">
              <span>[{{selectedList[item.id].mainbodyMedicine.medicineManufacturer || selectedList[item.id].manufacturer || '未配置'}}]</span>
              <span v-if="selectedList[item.id].mainbodyMedicine.medicineName !== item.medicineTypeName">{{selectedList[item.id].mainbodyMedicine.medicineName}}</span>
              <span class="packing">{{selectedList[item.id].mainbodyMedicine.medicinePacking}}</span>
              <span class="price">{{selectedList[item.id].mainbodyMedicine.price ? (selectedList[item.id].mainbodyMedicine.price + '元') : '未指定价格'}}</span>
              <span>{{selectedList[item.id].mainbodyMedicine.medicineType || selectedList[item.id].type || '-'}}</span>
              <template v-if="!selectedList[item.id].inventory || !selectedList[item.id].inventory.totalVolume">
                <span @click="inStock(selectedList[item.id])" v-if="!processed.includes(selectedList[item.id].id)" style="color: orange;" class="btn">[入库]</span>
                <span v-else style="color:#0066ff;">已入库</span>
              </template>
            </template>
            <template v-else>
              <span>[{{selectedList[item.id].manufacturer || '-'}}]</span>
              <span v-if="item.medicineTypeName !== item.medicineTypeName">{{item.medicineTypeName}}</span>
              <span class="packing">{{selectedList[item.id].packing}}</span>
              <span class="price">{{selectedList[item.id].price ? (selectedList[item.id].price + '元') : '未指定价格'}}</span>
              <span>{{selectedList[item.id].type || '-'}}</span>
              <span v-if="!processed.includes(selectedList[item.id].id)" @click="addMedichine(selectedList[item.id])" class="btn" style="color: red;">[新增]</span>
              <span v-else style="color:#0066ff;">已新增</span>
            </template>
            <span class="btn" @click="chooseMedicine(item.id, null)">重选</span>
          </div>
        </div>
        <div class="items">
          <div class="item">
            <!-- <div class="type-cate">{{item.medicineTypeName}}</div> -->
            <div class="medicine-list">
              <div class="medicine" v-if="!item.medicineList.length">
                <div class="wrap" style="cursor: default;color: #999;">暂无匹配药品</div>
              </div>
              <div class="medicine" v-for="medicine in item.medicineList" :key="medicine.id">
                <div class="wrap" @click="chooseMedicine(item.id, medicine)">
                  <template v-if="medicine.mainbodyMedicine">
                    <div class="name ellipsis" :title="medicine.mainbodyMedicine.medicineName" v-if="medicine.mainbodyMedicine.medicineName && medicine.mainbodyMedicine.medicineName !== item.medicineTypeName">{{medicine.mainbodyMedicine.medicineName}}</div>
                    <div class="manufacturer ellipsis" :title="medicine.mainbodyMedicine.medicineManufacturer">{{medicine.mainbodyMedicine.medicineManufacturer || '-'}}</div>
                    <div class="row-between">
                      <span class="packing">{{medicine.mainbodyMedicine.medicinePacking}}</span>
                      <span class="price">{{medicine.mainbodyMedicine.price}}元</span>
                    </div>
                    <div v-if="medicine.inventory && medicine.inventory.totalVolume">库存: [{{medicine.inventory.totalVolume}}]</div>
                    <div v-else class="action">
                      <span style="color: orange;">[库存不足]</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="name ellipsis" v-if="medicine.name !== item.medicineTypeName">{{medicine.name}}</div>
                    <div class="manufacturer ellipsis">{{medicine.manufacturer || '-'}}</div>
                    <div class="row-between">
                      <span class="packing">{{medicine.packing}}</span>
                      <span class="price">{{medicine.price}}元</span>
                    </div>
                    <div class="action">
                      <span style="color: red;">[无此药品]</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block-action">
      <fm-btn long @click="next">确定</fm-btn>
    </div>

    <fm-modal :value="status.add" title="新增药品" width="70vw" @cancel="status.add = false" :mask-closable="false">
      <PharmacyForm v-if="status.add" :medicine-id="chooseData.id" @submit="onAdd" />
    </fm-modal>

    <fm-modal :value="status.put" title="入库" width="400px" @cancel="status.put = false" :mask-closable="false">
      <PharmacyPut v-if="status.put" @success="onPut" @close="status.put = false" :data="chooseData" />
    </fm-modal>
  </div>
</template>

<script>
import { mainbodyMedicineRequest } from '@/api'
import PharmacyForm from '@/views/pharmacy/form.vue'
import PharmacyPut from '@/views/pharmacy/put.vue'

export default {
  components: { PharmacyForm, PharmacyPut },
  props: {
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      selectedList: {},
      processed: [],
      chooseData: null,
      status: {
        step: 'choose',
        put: false,
        add: false
      }
    }
  },
  computed: {
    dataList () {
      return this.data
    }
  },
  watch: {
    dataList: {
      deep: true,
      handler () {
        this.processed = []
        this.selectedList = {}
        this.data.forEach(item => {
          if (item.medicineList.length === 1) {
            this.chooseMedicine(item.id, item.medicineList[0])
          }
        })
      }
    }
  },
  methods: {
    inStock (data) {
      this.chooseData = { medicineId: data.id }
      this.status.put = true
    },
    addMedichine (data) {
      this.chooseData = { id: data.id }
      this.status.add = true
    },
    async onAdd (data) {
      const res = await mainbodyMedicineRequest.add(data)
      this.processed.push(res.medicineId)
      this.$notice.success('添加成功')
      this.status.add = false
    },
    onPut ({ medicineId }) {
      this.processed.push(medicineId)
      this.status.put = false
    },
    next () {
      const ids = Object.keys(this.selectedList).filter(v => this.selectedList[v]).map(v => Number(v))
      const noChoose = this.dataList.filter(item => !ids.includes(item.id))

      if (noChoose.length) {
        this.$dialog.info({ title: '以下药品未选择', content: noChoose.map(v => v.medicineTypeName).join('，')})
      } else {
        this.$emit('choose', ids.map(id => {
          return {
            prescription: this.dataList.find(v => v.id === id),
            medicine: this.selectedList[id]
          }
        }))
      }
    },
    chooseMedicine (id, medicine) {
      this.$set(this.selectedList, id, medicine)
    }
  },
}
</script>
