<style scoped lang="less">
  .tab-block {
    display: flex;
    flex-direction: column;
  }
  .tabs {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E9E9E9;
    div {
      word-break: keep-all;
      white-space: wrap;
      padding: 0 10px;
      line-height: 40px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all .3s;
      font-size: 15px;
      color: #999;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #2E6BE5;
        opacity: 0;
        position: absolute;
        transition: opacity .3s;
        left: 0;
        bottom: 0;
      }
      &:hover {
        color: #2E6BE5;
      }
      &.active {
        color: #2E6BE5;
        position: relative;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .tabls {
    margin-top: 10px;
    position: relative;
    flex: 1;
    height: 0;
    overflow: hidden;
    .table {
      position: absolute;
      z-index: 0;
      left: -100%;
      top: 0;
      width: 100%;
      transition: all .3s;
      overflow: auto;
      height: 100%;
      &.active {
        z-index: 1;
        left: 0;
        top: 0;
      }
    }
  }
</style>

<template>
  <div class="tab-block" style="z-index: 0;">
    <div class="tabs">
      <div :class="{active: activeTag && item.value === activeTag.value}" @click="activeTag = item" v-for="item in types" :key="item.cate">{{item.cate}}处方（{{backpackNums[item.action]}}）</div>
    </div>
    <div class="tabls">
      <component
        class="table"
        :class="{active: activeTag && activeTag.action === item.action}"
        @change="(groups) => backpackChange(item.action, groups)"
        v-for="(item, key) in backpack"
        :key="key"
        :data="prescriptionGroup.prescriptionData"
        :action="item.action"
        :is="item.component"
        :ref="item.action">
      </component>
    </div>
  </div>
</template>

<script>
import { usage, types } from '@/views/medicine/lib'

import BagsTable from './tables/bags.vue'
import SlicesTable from './tables/slices.vue'
import BottledTable from './tables/bottled.vue'
import MedicineTable from './tables/medicine.vue'
import InfusionTable from './tables/infusion.vue'

export default {
  components: { BagsTable, SlicesTable, BottledTable, MedicineTable, InfusionTable },
  props: {
    prescriptionGroupData: { type: Object, default: null }
  },
  data () {
    return {
      dataList: [],
      activeTag: types[0],
      prescriptionGroup: {
        prescriptionData: this.prescriptionGroupData ? this.prescriptionGroupData.prescriptionData : []
      },
      backpack: [
        { action: 'bags', component: BagsTable, groups: [] },
        { action: 'slices', component: SlicesTable, groups: [] },
        { action: 'bottled', component: BottledTable, groups: [] },
        { action: 'medicine', component: MedicineTable, groups: [] },
        { action: 'infusion', component: InfusionTable, groups: [] }
      ]
    }
  },
  watch: {
    prescriptionGroupData: {
      deep: true,
      handler () {
        if (this.prescriptionGroupData) {
          Object.keys(this.prescriptionGroup).forEach(key => {
            if (key === 'docRequire') {
              this.prescriptionGroup.docRequire = (this.prescriptionGroupData.docRequire || '').split(',')
            } else {
              this.prescriptionGroup[key] = this.prescriptionGroupData[key]
            }
          })
        } else {
          this.prescriptionGroup.prescriptionData = []
        }
      }
    }
  },
  computed: {
    usage () {
      return usage
    },
    types () {
      return types
    },
    columns () {
      return [
        { title: '序号', field: 'a1' },
        { title: '药品/规格', field: 'a1' },
        { title: '剂量', field: 'a2' },
        { title: '袋数', field: 'a3' },
        { title: '单价', field: 'a4' },
        { title: '金额', field: 'a5' },
        { title: '用法', field: 'a6' },
        { title: '操作', field: 'a7' }
      ]
    },
    backpackNums () {
      let nums = {}
      this.backpack.forEach(v => {
        nums[v.action] = v.groups.reduce((a, b) => {
          return a + b.list.length
        }, 0)
      })
      return nums
    }
  },
  methods: {
    getBackPack () {
      return this.backpack.map(item => {
        return item.groups.filter(v => v.list.length > 0).map(group => {
          return group.getData()
        })
      }).flat()

      // const prescriptionGroup = {
      //   executorName: this.prescriptionGroup.executorName,
      //   consignorName: this.prescriptionGroup.consignorName,
      //   docRequire: Array.isArray(this.prescriptionGroup.docRequire) ? this.prescriptionGroup.docRequire.join(',') : this.prescriptionGroup.docRequire,
      //   prescriptionData
      // }

      // this.$emit('submit', prescriptionGroup)
    },
    switchTag (tag) {
      this.activeTag = tag
    },
    addMedichine (medichine, tag, volume, origin) {
      if (this.$refs[tag.action]) {
        this.activeTag = tag
        this.$refs[tag.action][0].addMedichine(medichine, tag, volume, origin)
      }
    },
    backpackChange (action, data) {
      const index = this.backpack.findIndex(v => v.action === action)
      this.backpack[index].groups = data
    }
  }
}
</script>
