<style lang="less" scoped>
  .disease-query {
    background-color: #f8f8f8;
    display: flex;
  }
  .block {
    padding: 10px;
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    & + .block {
      margin-left: 10px;
    }
    .list {
      flex: 1;
      height: 0;
      overflow: auto;
    }
  }
  .row {
    & + .row {
      margin-top: 10px;
    }
    .field {
      display: flex;
      font-weight: bold;
      color: #000;
      font-size: 15px;
      align-items: center;
      margin-bottom: 10px;
      justify-content: space-between;
      span {
        font-size: 12px;
        color: #2F5BEA;
      }
    }
  }
  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
  }
  .prescription-list {
    .item {
      padding: 10px;
      border: 1px solid #ddd;
      transition: all .3s;
      border-radius: 5px;
      .tags {
        margin-top: 10px;font-size: 14px;color: #666;
        & > span + span {
          &::before {
            content: '，';
          }
        } 
      }
      & + .item {
        margin-top: 10px;
      }
      &:hover {
        border-color: #2F5BEA;
        .load-btn {
          color: #FFF;
          background-color: #2F5BEA;
        }
      }
      .load-btn {
        border-radius: 5px;
        padding: 2px 10px;
        transition: all .3s;
        cursor: pointer;
        border: 1px solid #2F5BEA;
        color: #2F5BEA;
      }
    }
  }
</style>

<template>
  <div class="disease-query">
    <div class="block">
      <div class="row">
        <div class="field">主诉</div>
        <Select @on-change="onSearchChange" size="large" placeholder="请选择或者输入主诉" v-model="search.symptom" filterable multiple allow-create @on-create="handlerSymptomCreate">
          <Option v-for="item in symptomList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
      </div>
      <div class="row">
        <div class="field">病症查询<span>可输入西医病名，查看中医对应症</span></div>
        <Select
          filterable
          clearable
          :remote-method="nameSearch"
          :loading="loading.nameSearch"
          @on-change="onSearchChange"
          size="large"
          placeholder="请输入病症名"
          v-model="search.name">
          <Option v-for="(option, index) in nameSearchList" :value="option.value" :key="index">{{option.label}}</Option>
        </Select>
      </div>
      <div class="row" style="color: red;">声明：诊疗结果仅供参考，实际以医生判断为准</div>
    </div>
    <div class="block">
      <div class="row">
        <div class="field">西医病症</div>
      </div>
      <div class="list" v-loadingx="loading.western">
        <WesternItem v-for="(item, index) in westernDataList" :data="item" :num="index + 1" :highlighted="search.symptom" @action="v => westernAction(v, index)" :active="active.western === index" :key="item.id" />
        <img src="/static/images/no-data.png" v-if="!westernDataList.length" class="no-data" />
      </div>
    </div>
    <div class="block">
      <div class="row">
        <div class="field">中医对应症</div>
      </div>
      <div class="list" v-loadingx="loading.chinese">
        <ChineseItem v-for="(item, index) in chineseDataList" :data="item" :num="index + 1" :highlighted="search.symptom" @action="v => chineseAction(v, index)" :key="item.id" />
        <img src="/static/images/no-data.png" v-if="!chineseDataList.length" class="no-data" />
      </div>
    </div>
    <fm-modal width="800px" v-model="status.details" title="病症解说">
      <template v-if="chooseData">
        <div style="font-weight: 800;color: #4f74ec;font-size: 18px;">{{chooseData.name}}</div>
        <fm-title style="color: #000;font-size: 16px !important" title-text="病症描述"></fm-title>
        <div>{{chooseData.description}}</div>
        <fm-title style="color: #000;font-size: 16px !important" title-text="辅助检查"></fm-title>
        <div>{{chooseData.examinationAide}}</div>
        <fm-title style="color: #000;font-size: 16px !important" title-text="体格检查"></fm-title>
        <div>{{chooseData.examinationPhysique}}</div>
        <fm-title style="color: #000;font-size: 16px !important" title-text="诊断及鉴别诊断"></fm-title>
        <div>{{chooseData.diagnosis}}</div>
      </template>
    </fm-modal>
    <fm-modal width="800px" v-model="status.plan" title="治疗方案">
      <template v-if="chooseData">
        <div style="min-height: 300px;">{{chooseData.treatmentPlan}}</div>
      </template>
    </fm-modal>
    <fm-modal width="800px" v-model="status.call" title="方剂调用">
      <div v-loadingx="loading.tmp">
        <template v-if="chooseData">
          <div style="font-weight: 800;color: #4f74ec;font-size: 18px;">{{chooseData.name}}</div>
          <fm-title style="color: #000;font-size: 16px !important" title-text="治法"></fm-title>
          <div>{{chooseData.treatmentPlan}}</div>
          <fm-title style="color: #000;font-size: 16px !important" title-text="处方"></fm-title>
          <div class="prescription-list">
            <div v-for="item in prescriptionTmpList" :key="item.id" class="item">
              <div style="display: flex;justify-content: space-between;">
                <span style="font-size: 16px;color: #000;">{{item.pname}}</span>
                <div class="load-btn" @click="chooseTmp(item)">载入处方</div>
              </div>
              <div class="tags">
                <span v-for="medicine in item.medicineData" :key="medicine.id">{{medicine.medicineTypeName}} {{medicine.useOnce}}{{medicine.unit}}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { Select, Option } from 'view-design'

import ChineseItem from './item/chinese.vue'
import WesternItem from './item/western.vue'

import { diseaseRequest, prescriptionTmpRequest } from '@/api'
Vue.component('Select', Select)
Vue.component('Option', Option)

export default {
  components: { ChineseItem, WesternItem },
  data () {
    return {
      chooseData: null,
      search: {
        symptom: [],
        disease: null
      },
      loading: {
        nameSearch: false,
        western: false,
        chinese: false,
        tmp: false,
      },
      active: {
        western: null
      },
      symptomList: ['发热', '头痛', '咳嗽', '咳痰', '呼吸困难', '腹痛', '腹泻', '腹胀', '胸痛', '心悸', '腰痛', '关节痛', '尿频', '尿急', '皮疹', '喷嚏', '干咳', '流脓涕'].map(v => ({label: v, value: v})),
      westernDataList: [],
      chineseDataList: [],
      nameSearchList: [],
      status: {
        details: false,
        plan: false,
        call: false
      },
      prescriptionTmpList: []
    }
  },
  methods: {
    chooseTmp (item) {
      this.status.call = false
      this.$emit('load', item)
    },
    async loadPrescriptionTmp () {
      const ids = this.chooseData.prescriptionTmpIds
      if (ids) {
        this.loading.tmp = true
        const res = await prescriptionTmpRequest.get({ ids: Array.isArray(ids) ? ids.join(',') : ids, pageNum: 1, pageSize: 100 })
        this.prescriptionTmpList = res.data
        this.loading.tmp = false
      } else {
        this.prescriptionTmpList = []
      }
    },
    chineseAction (action) {
      this.chooseData = action.data
      this.loadPrescriptionTmp()
      if (action.type === 'call') {
        this.status.call = true
      } else if (action.type === 'details') {
        this.status.details = true
      } else if (action.type === 'plan') {
        this.status.plan = true
      }
    },
    westernAction (action, index) {
      this.chooseData = action.data
      this.loadPrescriptionTmp()
      if (action.type === 'chinese') {
        this.loadChineseData(action.data.id)
        this.active.western = index
      } else if (action.type === 'details') {
        this.status.details = true
      } else if (action.type === 'plan') {
        this.status.plan = true
      }
    },
    handlerSymptomCreate (val) {
      if (this.symptomList.findIndex((value) => value === val) === -1) {
        this.symptomList.push({ value: val, label: val })
      }
    },
    async loadChineseData (id) {
      this.loading.chinese = true
      this.chineseDataList = await diseaseRequest.getSymptom({
        startDiseaseId: id
      })
      this.loading.chinese = false
    },
    async loadWesternData () {
      const param = {}

      if (this.search.symptom.length) {
        param.symptomList = this.search.symptom.join(',')
      }
      
      if (this.search.name) {
        param.name = this.search.name
      }

      if (Object.keys(param).length) {
        param.type = 'western'
        this.loading.western = true
        this.westernDataList = await diseaseRequest.getSymptom(param)
        this.loading.western = false
        if (this.westernDataList.length) {
          this.loadChineseData(this.westernDataList[0].id)
          this.active.western = 0
        } else {
          this.chineseDataList = []
        }
      } else {
        this.westernDataList = []
        this.chineseDataList = []
      }
    },
    async nameSearch (query) {
      if (query !== '') {
          this.loading.nameSearch = true
          const res = await diseaseRequest.getSymptom({
            name: query
          })
          this.nameSearchList = res.map(v => {
            return {
              label: v.name,
              value: v.name
            }
          })
          this.loading.nameSearch = false
      } else {
          this.nameSearchList = []
      }
    },
    onSearchChange () {
      this.loadWesternData()
    }
  },
}
</script>
