var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.groups),function(item,i){return _c('div',{key:i,staticClass:"group",class:{active: _vm.activeIndex === i},on:{"click":function($event){_vm.activeIndex = i}}},[_c('div',{staticClass:"title"},[_c('fm-form',{attrs:{"inline":""}},[_c('fm-form-item',{attrs:{"label":"总剂数"}},[_c('fm-input-number',{staticStyle:{"width":"100px"},attrs:{"min":1},model:{value:(item.timesNum),callback:function ($$v) {_vm.$set(item, "timesNum", $$v)},expression:"item.timesNum"}})],1),_c('fm-form-item',{attrs:{"label":"用药频率"}},[_c('fm-select',{attrs:{"clearable":"","filterable":"","placeholder":"请选择用药频率"},on:{"change":function($event){return item.setUsageFrequency()}},model:{value:(item.usageFrequency),callback:function ($$v) {_vm.$set(item, "usageFrequency", $$v)},expression:"item.usageFrequency"}},_vm._l((_vm.usageFrequency),function(t,i){return _c('fm-option',{key:i,attrs:{"value":t,"label":t}})}),1)],1)],1)],1),_c('div',{ref:"table",refInFor:true,staticClass:"group-table"},[_c('Table',{attrs:{"height":_vm.tableHeight,"columns":_vm.columns,"data":item.list,"no-data-text":"暂无数据"},scopedSlots:_vm._u([{key:"mdinfo",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('div',{staticClass:"md-name"},[_vm._v(" "+_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineName : row.medichine.name)+" / "+_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineEquivalent : row.medichine.equivalent)+" "+_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineEquivalentUnit : row.medichine.equivalentUnit)+" / "+_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineWeight : row.medichine.weight)+" "+_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineUnit : row.medichine.unit)+" ")]),_c('div',{staticClass:"md-type"},[_vm._v(_vm._s(row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineManufacturer : row.medichine.manufacturer))])])}},{key:"weight",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('div',{},[_vm._v(" "+_vm._s(row.medichine.weight * item.list[index].volume)+" ")])}},{key:"equivalent",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('div',{},[_c('InputNumber',{staticStyle:{"flex":"1"},attrs:{"formatter":function (v) { return v + ' ' + row.medichine.equivalentUnit; },"parser":function (v) { return Number(String(v).replace(/[^\d\.\-]/g, '')); },"min":0,"size":"small","active-change":false},on:{"on-change":function (value) { return _vm.equivalentChange(value, item, row, index); }},model:{value:(row.equivalent),callback:function ($$v) {_vm.$set(row, "equivalent", $$v)},expression:"row.equivalent"}})],1)}},{key:"volume",fn:function(ref){
var row = ref.row;
var index = ref.index;
return _c('div',{},[_vm._v(_vm._s(item.list[index].volume)+_vm._s(row.medichine.unit))])}},{key:"amount",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.amount))])}},{key:"origin",fn:function(ref){
var row = ref.row;
return _c('origin',{attrs:{"data":row.origin}})}},{key:"action",fn:function(ref){
var index = ref.index;
return _c('div',{},[_c('fm-btn',{attrs:{"size":"small"},on:{"click":function($event){return _vm.delMedichine(index)}}},[_vm._v("删除")])],1)}}],null,true)})],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }