<style scoped lang="less">
  .medicine-search-list {
    height: 100%;
    width: 100%;
    border: 1px solid #E9E9E9;
    border-top: none;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .menus {
      display: flex;
      .menu {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        line-height: 40px;
        cursor: pointer;
        flex: 1;
        text-align: center;
        background: #FAFAFA;
        border: 1px solid #E9E9E9;
        &.active {
          cursor: default;
          background: #FFF;
          color: #4170DE;
          border-color: #4170DE;
        }
      }
    }
    .bottom {
      height: 0;
      flex: 1;
      position: relative;
      .md-list {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      ul {
        overflow: auto;
        flex: 1;
        height: 0;
        &, li { padding: 0; margin: 0; }
        li {
          align-items: center;
          padding: 10px;
          list-style: none;
          display: flex;
          justify-content: space-between;
          border: 1px solid transparent;
          transition: all .3s;
          border-left: none;
          border-right: none;
          cursor: pointer;
          &:hover {
            border-color: #e8e8e8;
            background-color: rgba(213, 225, 250, 0.1);;
          }
          .info {
            flex: 1;
            white-space: pre-wrap;
            word-break: break-all;
          }
          .title {
            color: #666;
          }
          .sub-title {
            color: #999;
          }
        }
      }
    }
  }
  .tabs {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 0 5px;
    box-sizing: border-box;
    border-bottom: 1px solid #E9E9E9;
    div {
      word-break: keep-all;
      white-space: wrap;
      padding: 0 10px;
      line-height: 40px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all .3s;
      font-size: 15px;
      color: #999;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #2E6BE5;
        opacity: 0;
        position: absolute;
        transition: opacity .3s;
        left: 0;
        bottom: 0;
      }
      &:hover {
        color: #2E6BE5;
      }
      &.active {
        color: #2E6BE5;
        position: relative;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .medicine-details {
    padding: 10px;
    line-height: 2;
    color: #333;
  }
</style>

<template>
  <div class="medicine-search-list">
    <div class="top">
      <template v-if="groupId">
        <div class="menus">
          <div class="menu" :class="{active: details === null}" @click="details = null">药品库存</div>
          <div class="menu" :class="{active: details !== null}" @click="details = null">药品详情</div>
        </div>
      </template>
      <template v-else>
        <div class="menus">
          <div class="menu" :class="{active: status.tab === 'local'}" @click="switchTab('local')">诊所售药</div>
          <div class="menu" :class="{active: status.tab === 'share'}" @click="switchTab('share')">共享药房</div>
        </div>
      </template>

      <div style="margin: 10px;" v-if="details === null">
        <fm-input-new v-model="searchKey" @input="onSearch" clearable style="width: 100%" placeholder="请输入名称">
          <i slot="suffix" style="cursor: pointer;" @click="loadData" class="fmico fmico-search"></i>
        </fm-input-new>
      </div>

      <div class="tabs" v-if="details === null">
        <div v-for="item in types" :class="{active: activeTag && activeTag.value === item.value}" @click="switchTag(item)" :key="item.value">{{item.cate}}</div>
      </div>
    </div>

    <div class="bottom" v-loadingx="loading.load">
      <div v-if="details === null" class="md-list">
        <ul ref="ul" class="scrollbar">
          <li v-for="data in dataList" :key="data.id" @click="showDetails(data)">
            <div class="info">
              <div class="title">
                {{data.name || data.medicineName}}
                <span v-if="data.inventory">[余{{data.inventory.totalVolume || 0}}]</span>
                <span v-else>[无]</span>
                <span v-if="data.unit || data.medicineUnit"> / {{data.unit || data.medicineUnit}}</span>
              </div>
              <div class="sub-title">{{data.manufacturer || data.medicineManufacturer || '-'}}</div>
            </div>
            <div>
              <fm-btn @click.native.stop="$emit('choose', data, activeTag, 1, { type: status.tab })" text>添加</fm-btn>
            </div>
          </li>
        </ul>
        <fm-page
          style="margin: 10px auto;"
          @change="onPageChange"
          :current="page.num"
          :total="page.total"
          :page-size="page.size"
        />
      </div>
      <div v-else class="medicine-details">
        <div style="display: flex;justify-content: space-between;">
          <span style="font-weight: 800;">{{details.name || details.medicineName}}</span>
          <span @click="details = null" style="cursor: pointer;font-size:12px;color: #657180;display:flex;align-items: center;">
            关闭
          </span>
        </div>
        <div>生产厂家: {{details.manufacturer || details.medicineManufacturer}}</div>
        <div>{{getPackingName(details.type || details.medicineType)}}: {{details.packing || details.medicinePacking}}</div>
        <div>保质期: {{(details.mainbodyMedicine ? details.mainbodyMedicine.medicineShelfLife : (details.shelfLife || details.medicineShelfLife)) || '-'}}</div>
        <div>药品类型: {{details.type || details.medicineType}}</div>
        <div>库存: {{details.inventory ? (details.inventory.totalVolume || 0) : '无'}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mainbodyMedicineRequest, mainbodyMedicineGroupRequest } from '@/api'
import { types, getPackingName } from '../lib'

let timer = null

export default {
  props: {
    groupId: { type: Number, default: null }
  },
  data () {
    return {
      data: [],
      shareData: [],
      activeTag: types[0],
      details: null,
      searchKey: null,
      mainbodyMedicineGroupId: null,
      saleMainbodyId: null,
      status: {
        tab: this.groupId ? 'share' : 'local'
      },
      page: {
        num: 1,
        size: 20,
        total: 0
      },
      loading: {
        load: false
      }
    }
  },
  computed: {
    getPackingName () {
      return getPackingName
    },
    types () {
      return types
    },
    dataList () {
      return this.data
    }
  },
  watch: {
    groupId (id) {
      this.mainbodyMedicineGroupId = id
      this.status.tab = id ? 'share' : 'local'
      this.loadData()
    }
  },
  methods: {
    switchTab (type) {
      this.status.tab = type
      this.details = null
      this.page.num = 1
      this.loadData()
    },
    onPageChange (page) {
      this.page.num = page
      this.loadData()
    },
    switchTag (item) {
      this.activeTag = item
      this.page.num = 1
      this.loadData()
      this.$emit('tag-change', item)
    },
    onSearch () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.page.num = 1
        this.loadData()
      }, 800)
    },
    async loadData () {
      this.loading.load = true

      const parm = {
        pageNum: this.page.num,
        pageSize: this.page.size,
        medicineType: this.activeTag.value
      }

      if (this.searchKey) {
        parm.medicineName = this.searchKey
      }

      if (this.groupId || this.status.tab === 'share') {
        parm.mainbodyMedicineGroupId = this.mainbodyMedicineGroupId
      }

      let res = null
      if (this.status.tab === 'share') {
        res = await mainbodyMedicineRequest.get(parm)
        const base = !res.data.length ? [] : await mainbodyMedicineRequest.getBaseAll({ medicineIds: res.data.map(v => v.medicineId).join(',') })
        this.data = res.data.map(shareMedicine => {
          const baseMedicine = base.find(item => item.id === shareMedicine.medicineId)
          return Object.assign(baseMedicine, {
            shareMedicine
          })
        })
      } else {
        res = await mainbodyMedicineRequest.getBaseAll(parm)
        this.data = res.data
      }
      
      this.page.total = res.total
      this.loading.load = false
      this.$refs.ul.scrollTop = 0
    },
    showDetails (data) {
      this.details = data
    }
  },
  async mounted () {
    const groups = await mainbodyMedicineGroupRequest.getBuy({type: 'share'})
    if (this.groupId) {
      this.mainbodyMedicineGroupId = this.groupId
    } else {
      this.mainbodyMedicineGroupId = groups.length ? groups[0].id : null
    }
    this.saleMainbodyId = (groups.find(v => v.id === this.mainbodyMedicineGroupId) || { mainbodyId: null }).mainbodyId
    this.loadData()
  }
}
</script>
