<style scoped lang="less">
  .group {
    border: 1px solid #dedede;
    padding: 5px;
    transition: all .3s;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.active {
      border-color: #2E6BE5;
    }
    .title {
    }
    & + .group {
      margin-top: 20px;
    }
  }
  .group-table {
    flex: 1;
    height: 0;
    position: relative;
  }
  .md-name {
    font-size: 14px;
    color: #606266;
  }
  .md-type {
    color: #9ea7b4;
    font-size: 12px;
  }
</style>

<template>
  <div>
    <!-- 成药处方 -->
    <div class="group" :class="{active: activeIndex === i}" v-for="(item, i) in groups" :key="i" @click="activeIndex = i">
      <div class="group-table" ref="table">
        <Table :height="tableHeight" :columns="columns" :data="item.list" no-data-text="暂无数据">
          <div slot="mdinfo" slot-scope="{ row }">
            <div class="md-name">{{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineName : row.medichine.name}}</div>
            <div class="md-type">{{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineManufacturer : row.medichine.manufacturer}}</div>
          </div>
          <InputNumber :min="1" slot="volume" slot-scope="{ row, index }" style="width: 100%;" @input="v => {$set(item.list[index], 'volume', v); $set(item.list[index], 'amount', (row.volume * row.price).toFixed(2))}" v-model="row.volume"></InputNumber>
          <div slot="amount" slot-scope="{ row }">{{row.amount}}</div>
          <fm-select style="width: 100%;" slot="medicineUsage" slot-scope="{ row, index }" @change="v => $set(item.list[index], 'medicineUsage', v)" v-model="row.medicineUsage" clearable filterable placeholder="请选择用法">
            <fm-option v-for="(t, i) in usage" :key="i" :value="t" :label="t"></fm-option>
          </fm-select>
          <fm-select style="width: 100%;" slot="usageFrequency" slot-scope="{ row, index }" @change="v => $set(item.list[index], 'usageFrequency', v)" v-model="row.usageFrequency" clearable filterable placeholder="请选择用药频率">
            <fm-option v-for="(t, i) in usageFrequency" :key="i" :value="t" :label="t"></fm-option>
          </fm-select>
          <div slot="timesVolume" slot-scope="{ row, index }" style="width: 100%;display: flex;align-items: center;">
            <InputNumber style="flex: 1;" :min="1" @input="v => $set(item.list[index].timesVolume, 0, v)" v-model="row.timesVolume[0]"></InputNumber>
            <fm-select text style="width: 60px;margin-left: 5px;" clearable filterable v-model="row.timesVolume[1]" @input="v => $set(item.list[index].timesVolume, 1, v)">
              <fm-option v-for="(t, i) in measurement" :key="i" :label="t" :value="t"></fm-option>
            </fm-select>
          </div>
          <origin slot="origin" slot-scope="{ row }" :data="row.origin" />
          <div slot="action" slot-scope="{ index }">
            <fm-btn @click="delMedichine(index)" size="small">删除</fm-btn>
          </div>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { InputNumber } from 'view-design'
import TableMixins from './mixins'

Vue.component('InputNumber', InputNumber)

export default {
  mixins: [TableMixins],
  computed: {
    columns () {
      return [
        { title: '序号', type: 'index', width: 70, fixed: 'left' },
        { title: '药品名称', key: 'mdinfo', slot: 'mdinfo', fixed: 'left', width: 185 },
        { title: '开药量', key: 'volume', slot: 'volume', width: 130 },
        { title: '单价（元）', key: 'price', width: 130 },
        { title: '金额（元）', key: 'amount', slot: 'amount', width: 130 },
        { title: '用法', key: 'medicineUsage', slot: 'medicineUsage', width: 150 },
        { title: '用药频次', key: 'usageFrequency', slot: 'usageFrequency', width: 200 },
        { title: '每次用量', key: 'timesVolume', slot: 'timesVolume', width: 250 },
        { title: '来源', key: 'origin', fixed: 'right', slot: 'origin', width: 100 },
        { title: '操作', key: 'action', slot: 'action', width: 70, fixed: 'right' }
      ]
    }
  }
}
</script>
