/*
 * Author: hackerwand
 * Email: heipi@hackerwand.com
 * Date: Fri Nov 12 2021
 */
import Vue from 'vue'
import { Table, Button, InputNumber } from 'view-design'

import { Prescription } from './lib'
import { usage, usageFrequency, demand, drippingSpeed, measurement, types } from '@/views/medicine/lib'
import Origin from '@/views/patients/details/prescription/origin'

Vue.component('Table', Table)
Vue.component('Button', Button)
Vue.component('InputNumber', InputNumber)

export default {
  components: { Origin },
  props: {
    action: { type: String, default: null },
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      type: null,
      activeIndex: 0,
      groups: [],
      tableHeight: 0
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.loadGroup()
      }
    }
  },
  computed: {
    usageFrequency () {
      return usageFrequency
    },
    usage () {
      return usage
    },
    demand () {
      return demand
    },
    drippingSpeed () {
      return drippingSpeed
    },
    measurement () {
      return measurement
    }
  },
  methods: {
    equivalentChange (value, item, row, index) {
      if (['中药颗粒袋装', '中药颗粒瓶装'].includes(row.type)) {
        const equivalent = row.medichine.equivalent || row.medichine.medichineEquivalent
        const weight = row.medichine.weight || row.medichine.medichineWeight
        const unit = row.medichine.unit || row.medichine.medichineUnit

        const dose = Number((value / equivalent * weight).toFixed(2))
        let volume = unit === 'g' ? Number((value / equivalent).toFixed(2)) : Math.ceil(value / equivalent)

        this.$set(item.list[index], 'dose', dose)
        this.$set(item.list[index], 'volume', volume)
        this.$set(item.list[index], 'amount', row.price * volume)
      }
    },
    volumeChange (value, item, row, index) {
      this.$set(item.list[index], 'volume', value)
      let amount = Number((row.volume * row.price).toFixed(2))
      this.$set(item.list[index], 'amount', amount)
    },
    loadGroup () {
      const type = this.type.value
      if (this.data.length) {
        const groups = this.data.filter(v => v.type === type).map(v => new Prescription(type, v))
        this.groups = groups.length ? groups : [new Prescription(type)]
      } else {
        this.groups = [new Prescription(type)]
      }
      this.$emit('change', this.groups)
    },
    delMedichine (index) {
      this.groups[this.activeIndex].delMedichine(index)
      this.$emit('change', this.groups)
    },
    addMedichine (medichine, tag, volume, origin) {
      this.groups[this.activeIndex].addMedichine(medichine, tag, volume, origin)
      this.$emit('change', this.groups)
    },
    addGroup () {
      this.groups.push(new Prescription(this.type.value))
      this.$emit('change', this.groups)
    },
    delGroup (index) {
      this.groups.splice(index, 1)
      this.$emit('change', this.groups)
    },
    loadTableHeight () {
      if (this.$refs.table) {
        this.tableHeight = Array.isArray(this.$refs.table) ? this.$refs.table[0].offsetHeight : this.$refs.table.offsetHeight
      }
    }
  },
  mounted () {
    this.type = types.find(v => v.action === this.action)
    this.loadGroup()
    this.loadTableHeight()
    this.$nextTick(this.loadTableHeight)
    window.addEventListener('resize', this.loadTableHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.loadTableHeight)
  }
}