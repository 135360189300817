<style scoped lang="less">
  .prescription {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
  }
  .info {
    padding: 10px 10px 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex: 1;
    height: 0;
  }
  .submit {
    border: 1px solid #E9E9E9;
    border-bottom: none;
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #FFF;
    & > * {
      margin-left: 10px;
    }
  }
  .table {
    color: #666;
    font-size: 15px;
    tr {
      margin-top: 10px;
    }
    td {
      padding: 5px 0;
      min-width: 300px;
      span {
        color: #333;
      }
    }
  }
  .left {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 0;
    background-color: #FFF;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
  }
  .right {
    border-radius: 5px;
    background-color: #FFF;
    margin-left: 10px;
    position: relative;
    height: 100%;
    width: 350px;
  }
  .form-flex {
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  td + td {
    padding-left: 50px !important;
  }
  .title-action {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
</style>

<style lang="less">
.prescription {
  .info {
    .left {
      .fm-tabs .fm-tabs-mini .fm-tabs-content {
        margin-top: 10px;
      }
    }
  }
}
</style>

<style scoped lang="less">
  .tab-block {
    display: flex;
    flex-direction: column;
  }
  .tabs {
    display: flex;
    width: 100%;
    overflow: auto;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #E9E9E9;
    div {
      word-break: keep-all;
      white-space: wrap;
      padding: 0 10px;
      line-height: 40px;
      cursor: pointer;
      box-sizing: border-box;
      transition: all .3s;
      font-size: 15px;
      color: #999;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        background-color: #2E6BE5;
        opacity: 0;
        position: absolute;
        transition: opacity .3s;
        left: 0;
        bottom: 0;
      }
      &:hover {
        color: #2E6BE5;
      }
      &.active {
        color: #2E6BE5;
        position: relative;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .tabls {
    margin-top: 10px;
    position: relative;
    flex: 1;
    height: 0;
    overflow: hidden;
    .table {
      position: absolute;
      z-index: 0;
      left: -100%;
      top: 0;
      width: 100%;
      transition: all .3s;
      overflow: auto;
      height: 100%;
      &.active {
        z-index: 1;
        left: 0;
        top: 0;
      }
    }
  }
</style>

<template>
  <div class="prescription">
    <div class="info">
      <div class="left">
        <div class="title-action">
          <router-link v-if="patient.id" :to="{name: 'patients.details.info', query: {id: patient.id}}">
            <span class="fm-btn fm-btn-small fm-btn-norm">电子病历</span>
          </router-link>

          <fm-btn style="margin-left: 10px;" size="small" @click="status.query = true">患者查询</fm-btn>
          <fm-btn style="margin-left: 10px;" size="small" @click="status.tmp = true">处方调用</fm-btn>
        </div>
        <div class="tab-block" style="height: 260px;">
          <div class="tabs">
            <div :class="{active: status.tab === 'info'}" @click="status.tab = 'info'">患者信息</div>
            <div :class="{active: status.tab === 'doctor'}" @click="status.tab = 'doctor'">诊断信息</div>
          </div>
          <div class="tabls">
            <div class="table scrollbar" :class="{active: status.tab === 'info'}">
              <fm-form :inline="3" label-align="right" label-width="120px">
                <fm-form-item label="姓名：">
                  <fm-input-new style="flex: 1;" placeholder="请输入姓名" v-model="patient.name"></fm-input-new>
                </fm-form-item>
                <fm-form-item label="联系方式：">
                  <fm-input-new style="flex: 1;" placeholder="请输入联系方式" v-model="patient.phone"></fm-input-new>
                </fm-form-item>
                <fm-form-item label="性别：">
                  <fm-radio-group style="display: inline-flex;" v-model="patient.sex" name="sex">
                    <fm-radio label="男" value="男"></fm-radio>
                    <fm-radio label="女" value="女"></fm-radio>
                  </fm-radio-group>
                </fm-form-item>
                <fm-form-item label="身份证号：">
                  <fm-input-new style="flex: 1;" placeholder="请输入身份证号" v-model="patient.idNo"></fm-input-new>
                </fm-form-item>
                <fm-form-item label="年龄：">
                  <fm-input-number style="flex: 1;" :min="0" v-model="patient.age"></fm-input-number>
                </fm-form-item>
                <fm-form-item label="居住地址：">
                  <fm-input-new style="flex: 1;" placeholder="请输入居住地址" v-model="patient.address"></fm-input-new>
                </fm-form-item>
              </fm-form>
              <FmTabs size="mini">
                <FmTabPane title="过敏史">
                  <fm-input-new style="width: 100%;" placeholder="请输入过敏史" v-model="patient.hisAllergy"></fm-input-new>
                </FmTabPane>
                <FmTabPane title="既往史">
                  <fm-input-new style="width: 100%;" placeholder="请输入既往史" v-model="patient.hisPast"></fm-input-new>
                </FmTabPane>
                <FmTabPane title="家族史">
                  <fm-input-new style="width: 100%;" placeholder="请输入家族史" v-model="patient.hisFamily"></fm-input-new>
                </FmTabPane>
                <FmTabPane title="个人史">
                  <fm-input-new style="width: 100%;" placeholder="请输入个人史" v-model="patient.hisPersonal"></fm-input-new>
                </FmTabPane>
              </FmTabs>
            </div>
            <div class="table" :class="{active: status.tab === 'doctor'}">
              <fm-form :inline="3" label-width="100px">
                <fm-form-item label="就诊类型：">
                  <fm-radio-group name="type" v-model="diagnosis.type">
                    <fm-radio label="初诊" value="初诊"></fm-radio>
                    <fm-radio label="复诊" value="复诊"></fm-radio>
                    <fm-radio label="急诊" value="急诊"></fm-radio>
                  </fm-radio-group>
                </fm-form-item>
                <fm-form-item label="发病时间：">
                  <FmDatePicker type="datetime" :value="diagnosis.startTime" @change="(startTime) => {diagnosis.startTime = startTime}" placeholder="请选择发病时间" format="Y-M-D H:I:S"></FmDatePicker>
                </fm-form-item>
                <fm-form-item label="处方时间：">
                  <FmDatePicker type="datetime" :value="diagnosis.endTime" @change="(endTime) => {diagnosis.endTime = endTime}" placeholder="请选择发病时间" format="Y-M-D H:I:S"></FmDatePicker>
                </fm-form-item>
                <fm-form-item label="科室：">
                  <fm-input-new v-model="diagnosis.orgName" placeholder="请输入科室" />
                </fm-form-item>
                <fm-form-item label="医生姓名：">
                  <fm-input-new v-model="diagnosis.executorName" placeholder="请输入医生姓名" />
                </fm-form-item>
                <fm-form-item label="主诉：" style="width: 100%;">
                  <div style="width: 100%;display: flex;">
                    <Select transfer style="flex: 1; width: 0;" v-model="diagnosis.description" clearable filterable multiple allow-create @on-create="handlerDescriptionCreate" placeholder="请输入主诉">
                      <Option v-for="(item, i) in descriptionList" :key="i" :value="item.value" :label="item.label"></Option>
                    </Select>
                    <fm-btn style="margin-left: 10px;" @click="status.disease = true">
                      <i class="iconfont icon-search"></i>
                      辅助诊断
                    </fm-btn>
                  </div>
                </fm-form-item>
                <fm-form-item label="中医诊断：" style="width: 100%;">
                  <fm-input-new v-model="diagnosis.result" placeholder="中医诊断"/>
                </fm-form-item>
              </fm-form>
            </div>
          </div>
        </div>
        <MedicinePrescription style="flex: 1;height: 0;" :key="loadKey" ref="prescription" :prescription-group-data="prescriptionGroupData" />
      </div>
      <div class="right">
        <MedicineSearchList ref="pMedicineSearchList" @tag-change="onMedichieTagChange" @choose="onMedichineChoose" />
      </div>
    </div>

    <div class="submit">
      <span>开方人</span>
      <fm-input-new v-model="submitData.executorName" clearable filterable placeholder="请选择" />
      <span>发药人</span>
      <fm-input-new v-model="submitData.consignorName" clearable filterable placeholder="请选择" />
      <span>医嘱</span>
      <Select style="flex: 1; width: 0;" v-model="submitData.docRequire" clearable filterable multiple allow-create @on-create="handlerDoctorAdviceCreate" placeholder="请选择">
        <Option v-for="(item, i) in doctorAdvice" :key="i" :value="item.value" :label="item.label"></Option>
      </Select>
      <fm-btn v-loadingx="loading.submit" @click="submit">保存收费</fm-btn>
    </div>

    <fm-modal v-model="status.query" width="800px" title="患者查询">
      <UpdateQuery :key="loadKey" @query="query" />
    </fm-modal>

    <fm-modal v-model="status.tmp" width="1000px" title="处方调用">
      <PrescriptionTmp :isUse="true" @chooseTmp="chooseTmp" style="height: 80vh;" v-if="status.tmp"/>
    </fm-modal>

    <fm-modal :mask-closable="false" title="收款" v-model="status.pay" width="650px">
      <pay @cancel="payCancel" @payOver="payOver" type="get" :order-id="order.id" v-if="order && order.id"></pay>
    </fm-modal>

    <fm-modal :mask-closable="false" title="历史病历" width="800px" v-model="status.his">
      <div style="height: 60vh; position: relative;">
        <his-cases v-if="status.his && patientId" :patient-id="patientId" out-load @out-load="loadHisPrescription" />
      </div>
    </fm-modal>

    <fm-modal :mask-closable="false" title="辅助诊断" v-model="status.disease" width="80vw">
      <disease-query style="height: 70vh;min-height: 300px;padding: 10px;" @load="chooseTmp" />
    </fm-modal>

    <fm-modal :mask-closable="false" title="处方药品调用" v-model="status.callMedichineList" width="1000px">
      <prescription-call-medicine @choose="callMedichineChoose" :data="callMedichineList" />
    </fm-modal>

    <fm-modal :mask-closable="false" title="共享药房" v-model="status.purchase" width="1000px">
      <share-medicine-purchase @submit="data => (submit(false, data), status.purchase = false)" :data="purchaseList" />
    </fm-modal>

    <fm-modal :mask-closable="false" title="处方调用药品类型" v-model="status.callMedichineTypes" width="1000px" @cancel="callMedichineTypesCancal">
      <call-medichine-types @confirm="callMedichineTypesConfirm" />
    </fm-modal>
  </div>
</template>

<script>
// prescriptionTmpRequest.mainBodyMedicine 根据处方id获取可选药品
// mainbodyMedicineGroupRequest.getBuy 获取可用共享药房
// mainbodyMedicineRequest.get({mainbodyMedicineGroupId: 1}) 获取共享药房药品明细

/* eslint-disable no-unused-vars */
import { patientRequest, illnessCaseRequest, orderRequest, prescriptionTmpRequest, mainbodyMedicineRequest } from '@/api'
import UpdateQuery from './query.vue'
import MedicineSearchList from '@/views/medicine/search/prescription.vue'
import PrescriptionTmp from '@/views/prescription/tmp/tmpContent.vue'
import MedicinePrescription from './prescription/index.vue'
import Pay from '@/views/order/detail/pay'
import HisCases from './cases'
import DiseaseQuery from '@/views/disease/query.vue'
import PrescriptionCallMedicine from '@/views/prescription/call/medicine.vue'
import ShareMedicinePurchase from '@/views/medicine/share/purchase.vue'
import CallMedichineTypes from '@/views/prescription/call/types.vue'

import { types, doctorAdvice } from '@/views/medicine/lib'

import Vue from 'vue'
import { Select, Option } from 'view-design'

Vue.component('Select', Select)
Vue.component('Option', Option)

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {
    UpdateQuery,
    MedicineSearchList,
    MedicinePrescription,
    Pay,
    HisCases,
    PrescriptionTmp,
    DiseaseQuery,
    PrescriptionCallMedicine,
    ShareMedicinePurchase,
    CallMedichineTypes 
  },
    data () {
    return {
      loadKey: 0,
      patient: {},
      diagnosis: {
        type: '初诊',
        startTime: null,
        orgName: null,
        executorName: null,
        endTime: null,
        description: [],
        result: null
      },
      prescriptionGroupData: null,
      status: {
        query: false,
        pay: false,
        his: false,
        tmp: false,
        disease: false,
        callMedichineList: false,
        tab: 'info',
        purchase: false,
        callMedichineTypes: false
      },
      order: null,
      newIllnessCaseId: null,
      submitData: {
        executorName: null,
        consignorName: null,
        docRequire: null
      },
      doctorAdviceCreateList: [],
      descriptionCreateList: [],
      callMedichineList: [],
      purchaseList: [],
      chooseTmpTypeData: null,
      addPharmacyMedichineList: [],
      loading: {
        submit: false
      }
    }
  },
  computed: {
    id () {
      return this.$route.query.id || null
    },
    illnessCaseId () {
      return this.$route.query.illnessCaseId || null
    },
    diagnosisType () {
      return this.diagnosis.type
    },
    patientId () {
      return this.patient.id
    },
    doctorAdvice () {
      let docRequire = this.submitData.docRequire || []

      return Array.from(new Set([
        ...docRequire, ...this.doctorAdviceCreateList, ...doctorAdvice
      ])).map(v => {
        return { label: v, value: v }
      })
    },
    descriptionList () {
      let description = this.diagnosis ? this.diagnosis.description : []

      if (description && description.length && !Array.isArray(description)) {
        description = description.split(/[,，、；;\s]/).filter(v => v)
      }

      return Array.from(new Set([...description, ...this.descriptionCreateList, '发热', '头痛', '咳嗽', '咳痰', '呼吸困难', '腹痛', '腹泻', '腹胀', '胸痛', '心悸', '腰痛', '关节痛', '尿频', '尿急', '皮疹', '喷嚏', '干咳', '流脓涕'])).map(v => {
        return { label: v, value: v }
      })
    }
  },
  watch: {
    id () {
      this.loadData()
    },
    illnessCaseId () {
      this.loadIllnessCaseData()
    },
    patientId () {
      this.confirmLoadHisDiagnosis()
    },
    diagnosisType () {
      this.confirmLoadHisDiagnosis()
    }
  },
  methods: {
    callMedichineChoose (list) {
      this.status.callMedichineList = false

      list.forEach(item => {
        let volume = this.getVolume(item.prescription, item.medicine)
        const tag = types.find(v => v.value === item.medicine.type)
        this.onMedichineChoose(item.medicine, tag, volume, this.chooseTmpTypeData.shareGroupId ? {
          type: 'prescription-share',
          shareGroupId: this.chooseTmpTypeData.shareGroupId
        } : { type: 'prescription-local' })
      })
    },
    handlerDoctorAdviceCreate (val) {
      if (this.doctorAdvice.findIndex((value) => value === val) === -1) {
        this.doctorAdviceCreateList.push(val)
      }
    },
    handlerDescriptionCreate (val) {
      if (this.descriptionList.findIndex((value) => value === val) === -1) {
        this.descriptionCreateList.push(val)
      }
    },
    getVolume (pData, mData) {
      let volume = 1
      if (mData.equivalent && mData.equivalentUnit && mData.equivalentUnit === pData.unit) {
        volume = mData.equivalentUnit === 'g' ? (pData.useOnce / mData.equivalent) : Math.ceil(pData.useOnce / mData.equivalent)
      } else {
        volume = pData.equivalentUnit !== 'g' ? Math.ceil(pData.useOnce) : pData.useOnce
      }
      return Number(volume.toFixed(2))
    },
    callMedichineTypesCancal () {
      this.callMedichineTypesPromise.reject()
    },
    callMedichineTypesConfirm (data) {
      this.callMedichineTypesPromise.resolve(data)
    },
    async chooseTmp (parm) {
      if (!parm.medicineData || !parm.medicineData.length) {
        return this.$notice.warning('处方中无药品明细，无法调用')
      }
      
      this.status.callMedichineTypes = true
      const promise = new Promise((resolve, reject) => {
        this.callMedichineTypesPromise = { resolve, reject }
      })

      promise.then(async (data) => {
        this.chooseTmpTypeData = data
        this.callMedichineList = await prescriptionTmpRequest.mainBodyMedicine(parm.id, data)
        this.status.callMedichineList = true
      }).catch(() => {
        // 取消
      }).finally(() => {
        this.status.callMedichineTypes = false
        this.status.tmp = false
        this.status.disease = false
      })
    },
    loadHisPrescription ({ router }) {
      this.status.his = false
      if (router.query.illnessCaseId !== this.illnessCaseId) {
        this.$router.replace(router)
      }
    },
    async confirmLoadHisDiagnosis () {
      if (this.diagnosisType === '复诊' && this.patientId) {
        const confirm = await this.$dialog.confirm({title: '提示', content: '是否加载[' + this.patient.name + ']历史病历'})
        if (confirm) {
          this.status.his = true
        }
      }
    },
    async payCancel () {
      await illnessCaseRequest.del(this.newIllnessCaseId)
      this.status.pay = false
      this.reset()
    },
    async payOver () {
      this.status.pay = false
      this.reset()

      if (this.addPharmacyMedichineList && this.addPharmacyMedichineList.length) {
        const promises = this.addPharmacyMedichineList.map(item => {
          return new Promise(resolve => {
            mainbodyMedicineRequest.add(item).then(() => {
              resolve({ status: true, item, msg: '成功' })
            }).catch(err => {
              resolve({ status: false, item, msg: err.message })
            })
          })
        })

        const result = await Promise.all(promises)
        const errList = result.filter(v => !v.status)
        const successList = result.filter(v => v.status)

        this.$dialog.info({
          title: '药房提示',
          content: '<span>' + successList.length + '种药已成功添加至药房中</span>' + (errList.length ? ('<br /><span style="color: red;">' + errList.length + '种药添加失败或已存在！<span>') : '')
        })

        this.$refs.pMedicineSearchList.loadData()
      }
    },
    reset () {
      this.patient = {}
      this.diagnosis.type = '初诊'
      this.diagnosis.startTime = null
      this.diagnosis.orgName = null
      this.diagnosis.executorName = null
      this.diagnosis.endTime = null
      this.diagnosis.description = []
      this.diagnosis.result = null
      this.prescriptionGroupData = null
      this.submitData.executorName = null
      this.submitData.consignorName = null
      this.submitData.docRequire = null
      this.loadKey++
      this.order = null
      this.newIllnessCaseId = null
    },
    onMedichieTagChange (tag) {
      this.$refs.prescription.switchTag(tag)
    },
    onMedichineChoose (medichine, tag, volume, origin) {
      this.$refs.prescription.addMedichine(medichine, tag, volume, origin)
    },
    query (data) {
      this.patient = data
      this.status.query = false
      this.status.tab = 'info'
    },
    async submit (checkPurchase = true, prescriptionPurchaseData = null) {
      this.loading.submit = true
      const diagnosis = JSON.parse(JSON.stringify(this.diagnosis, function (key, value) {
        if (key === 'description') {
          return Array.isArray(value) ? value.join(',') : value
        } else {
          return value
        }
      }))

      const data = {
        diagnosisData: diagnosis,
        prescriptionGroupData: {
          executorName: this.submitData.executorName,
          consignorName: this.submitData.consignorName,
          docRequire: (this.submitData.docRequire || []).join(','),
          prescriptionData: this.$refs.prescription.getBackPack()
        }
      }

      const medicineIds = data.prescriptionGroupData.prescriptionData.map(({medicineData}) => {
        return medicineData.map(v => v.medicineId)
      }).flat()

      if (!medicineIds.length) {
        this.$notice.warning('药品信息为空')
      } 
      
      let sharePurchaseList = []
      let prescriptionPurchaseList = []
      let medicineList = []
      let localBottledMedicineList = []

      if (medicineIds.length) {
        this.$refs.prescription.backpack.forEach(item => {
          item.groups.filter(v => v.list.length > 0).forEach(group => {
            group.list.forEach(v => {
              if (v.origin.type === 'prescription-local') {
                prescriptionPurchaseList.push(v)
              } else if (v.origin.type === 'share' || v.origin.type === 'prescription-share') {
                sharePurchaseList.push(v)
              } else if (v.type === '中药颗粒瓶装' && v.origin.type === 'local') {
                localBottledMedicineList.push(v)
              }
            })
          })
        })
      }

      if ((prescriptionPurchaseList.length || localBottledMedicineList.length) && checkPurchase) {
        medicineList = await mainbodyMedicineRequest.getBaseAll({
          medicineIds: [...prescriptionPurchaseList, ...localBottledMedicineList].map(v => v.medicineId).join(',')
        })
      }

      this.purchaseList = medicineList.filter(v => !v.mainbodyMedicine || !v.inventory || !v.inventory.totalVolume || v.inventory.totalVolume < 0)
      console.log('purchaseList', this.purchaseList)

      if (this.purchaseList.length && checkPurchase) {
        this.status.purchase = true
      } else {
        if (this.patient.id) {
          await patientRequest.update(this.patient.id, this.patient)
        } else {
          this.patient.id = await patientRequest.add(this.patient)
        }

        data.patientId = this.patient.id

        this.newIllnessCaseId = await illnessCaseRequest.add(data)
        this.$notice.success('处方信息保存成功')

        this.addPharmacyMedichineList = []

        // 最后调用 用于生成患者于诊所之间的订单
        if (medicineIds.length) {
          await orderRequest.illnessCase(this.newIllnessCaseId)

          let getPurchaseVolume = (medicineId, type) => {
            let backpackMedicineInfo = null, backpackGroupInfo = null
            for (let backpack of this.$refs.prescription.backpack) {
              for (let group of backpack.groups) {
                for (let v of group.list) {
                  if (v.medicineId === medicineId && ((Array.isArray(type) && type.includes(v.origin.type)) || (v.origin.type === type))) {
                    backpackMedicineInfo = v;break
                  }
                }
                if (backpackMedicineInfo) {
                  backpackGroupInfo = group;break
                }
              }
              if (backpackMedicineInfo && backpackGroupInfo) {
                break
              }
            }
            return backpackGroupInfo.timesNum * backpackMedicineInfo.volume
          }

          let purchaseDetailsList = {
            prescription: { saleMainbodyId: null, mainbodyMedicineGroupId: null, list: [] },
            share: { saleMainbodyId: null, mainbodyMedicineGroupId: null, list: [] }
          }
        
          // 共享药房 - 处方
          if (prescriptionPurchaseData) {
            // 生成采购单采购
            if (prescriptionPurchaseData.type === 'share') {
              purchaseDetailsList.prescription.saleMainbodyId = prescriptionPurchaseData.saleMainbodyId
              purchaseDetailsList.prescription.mainbodyMedicineGroupId = prescriptionPurchaseData.mainbodyMedicineGroupId
              prescriptionPurchaseData.details.forEach(item => {
                const volume = getPurchaseVolume(item.medicine.id, ['local', 'prescription-local'])
                purchaseDetailsList.prescription.list.push({
                  volume: volume,
                  medicineId: item.medicine.id,
                  price: item.share.price,
                  total: volume * item.share.price
                })
              })
            }
          }

          if (sharePurchaseList.length) {
            purchaseDetailsList.share.saleMainbodyId = this.$refs.pMedicineSearchList.saleMainbodyId
            purchaseDetailsList.share.mainbodyMedicineGroupId = this.$refs.pMedicineSearchList.mainbodyMedicineGroupId
            sharePurchaseList.map(item => {
              const volume = getPurchaseVolume(item.medicineId, ['share', 'prescription-share'])
              const price = item.medichine.shareMedicine ? item.medichine.shareMedicine.price : item.price
              purchaseDetailsList.share.list.push({
                volume: volume,
                medicineId: item.medicineId,
                price: price,
                total: volume * price
              })
            })
          }

          // 不存在的药品自动添加到药房
          const nonexistentCheck = await mainbodyMedicineRequest.getBaseAll({
            medicineIds: medicineIds.join(',')
          })
          this.addPharmacyMedichineList = nonexistentCheck.filter(v => !v.mainbodyMedicine).map(item => {
            return {
              medicineId: item.id,
              avgPurchasePrice: item.price,
              price: item.price,
              warningValue: null,
              productionBatch: null,
              productionDate: null,
              expiredDate: null,
              volume: 0
            }
          })

          if (purchaseDetailsList.prescription.list.length || purchaseDetailsList.share.list.length) {
            if (purchaseDetailsList.prescription.saleMainbodyId === purchaseDetailsList.share.saleMainbodyId && purchaseDetailsList.prescription.mainbodyMedicineGroupId === purchaseDetailsList.share.mainbodyMedicineGroupId) {
              await orderRequest.add({
                saleMainbodyId: purchaseDetailsList.prescription.saleMainbodyId,
                mainbodyMedicineGroupId: purchaseDetailsList.prescription.mainbodyMedicineGroupId,
                details: [...purchaseDetailsList.prescription.list, ...purchaseDetailsList.share.list],
                sourceType: 'illness_case',
                sourceDataId: this.newIllnessCaseId
              })
            } else {
              const purchaseOrderPromise = Object.keys(purchaseDetailsList).filter(type => purchaseDetailsList[type].saleMainbodyId && purchaseDetailsList[type].list.length).map(type => {
                return orderRequest.add({
                  saleMainbodyId: purchaseDetailsList[type].saleMainbodyId,
                  mainbodyMedicineGroupId: purchaseDetailsList[type].mainbodyMedicineGroupId,
                  details: purchaseDetailsList[type].list,
                  sourceType: 'illness_case',
                  sourceDataId: this.newIllnessCaseId
                })
              })
              await Promise.all(purchaseOrderPromise)
            }
            this.$notice.success('共享药房采购单已生成')
          }

          const orders = await orderRequest.get({ illnessCaseId: this.newIllnessCaseId })
          this.order = orders.pop()
  
          this.status.pay = true
        }
      }
      this.loading.submit = false
    },
    async loadData () {
      try {
        if (this.id) {
          let data = await patientRequest.get({id: this.id})
          if (data.length) {
            this.patient = data[0]
          } else {
            throw new Error('患者信息不存在')
          }
        }
      } catch (error) {
        this.$notice.warning({title: '提示', desc: error.message})
        this.$router.replace({name: 'patients.index'})
      }
    },
    resetIllnessCaseData () {
      this.prescriptionGroupData = null
      this.submitData.executorName = null
      this.submitData.consignorName = null
      this.submitData.docRequire = null

      this.diagnosis.type = '初诊'
      this.diagnosis.startTime = null
      this.diagnosis.orgName = null
      this.diagnosis.executorName = null
      this.diagnosis.endTime = null
      this.diagnosis.description = []
      this.diagnosis.result = null
    },
    async loadIllnessCaseData () {
      if (this.id && this.illnessCaseId) {
        const illnessCaseData = await illnessCaseRequest.get({ id: this.illnessCaseId })
        if (illnessCaseData && illnessCaseData.length) {
          Object.keys(this.diagnosis).forEach(key => {
            if (key === 'description') {
              this.diagnosis[key] = (illnessCaseData[0].diagnosisData[key] || '').split(/[,，、；;\s]/).filter(v => v)
            } else {
              this.diagnosis[key] = illnessCaseData[0].diagnosisData[key]
            }
          })
          const medicineIds = []
          illnessCaseData[0].prescriptionGroupData.prescriptionData.forEach(group => {
            group.medicineData.forEach(item => {
              medicineIds.push(item.medicineId)
            })
          })
          const medicineList = await mainbodyMedicineRequest.getBaseAll({
            medicineIds: medicineIds.join(',')
          })
          illnessCaseData[0].prescriptionGroupData.prescriptionData.forEach(group => {
            group.medicineData.forEach(item => {
              item.medicine = medicineList.find(v => v.id === item.medicineId)
            })
          })
          
          this.prescriptionGroupData = illnessCaseData[0].prescriptionGroupData
          this.submitData.executorName = this.prescriptionGroupData ? this.prescriptionGroupData.executorName : null
          this.submitData.consignorName = this.prescriptionGroupData ? this.prescriptionGroupData.consignorName : null
          this.submitData.docRequire = this.prescriptionGroupData && this.prescriptionGroupData.docRequire ? this.prescriptionGroupData.docRequire.split(',') : null
        } else {
          this.resetIllnessCaseData()
        }
      } else {
        this.resetIllnessCaseData()
      }
    }
  },
  mounted () {
    this.loadData()
    this.loadIllnessCaseData()
  }
}
</script>
