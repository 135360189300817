<style scoped lang="less">
  .group {
    border: 1px solid #dedede;
    padding: 5px;
    transition: all .3s;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.active {
      border-color: #2E6BE5;
    }
    .title {
    }
    & + .group {
      margin-top: 20px;
    }
  }
  .group-table {
    flex: 1;
    height: 0;
    position: relative;
  }
  .md-name {
    font-size: 14px;
    color: #606266;
  }
  .md-type {
    color: #9ea7b4;
    font-size: 12px;
  }
</style>

<template>
  <!-- 瓶装  -->
  <div>
    <div class="group" :class="{active: activeIndex === i}" v-for="(item, i) in groups" :key="i" @click="activeIndex = i">
      <div class="title">
        <fm-form inline>
          <fm-form-item label="总剂数">
            <fm-input-number :min="1" style="width: 100px" v-model="item.timesNum"></fm-input-number>
          </fm-form-item>
          <fm-form-item label="用药频率">
            <fm-select v-model="item.usageFrequency" @change="item.setUsageFrequency()" clearable filterable placeholder="请选择用药频率">
              <fm-option v-for="(t, i) in usageFrequency" :key="i" :value="t" :label="t"></fm-option>
            </fm-select>
          </fm-form-item>
        </fm-form>
      </div>
      <div class="group-table" ref="table">
        <Table :height="tableHeight" :columns="columns" :data="item.list" no-data-text="暂无数据">
          <div slot="mdinfo" slot-scope="{ row }">
            <div class="md-name">
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineName : row.medichine.name}}
              /
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineEquivalent : row.medichine.equivalent}}
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineEquivalentUnit : row.medichine.equivalentUnit}}
              /
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineWeight : row.medichine.weight}}
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineUnit : row.medichine.unit}}
            </div>
            <div class="md-type">{{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineManufacturer : row.medichine.manufacturer}}</div>
          </div>
          <div slot="weight" slot-scope="{ row, index }">
            {{row.medichine.weight * item.list[index].volume}}
          </div>
          <div slot="equivalent" slot-scope="{ row, index }">
            <InputNumber :formatter="(v) => v + ' ' + row.medichine.equivalentUnit" :parser="(v) => Number(String(v).replace(/[^\d\.\-]/g, ''))" :min="0" size="small" style="flex: 1" :active-change="false" @on-change="value => equivalentChange(value, item, row, index)" v-model="row.equivalent" />
          </div>
          <div slot="volume" slot-scope="{ row, index }">{{item.list[index].volume}}{{row.medichine.unit}}</div>
          <div slot="amount" slot-scope="{ row }">{{row.amount}}</div>
          <origin slot="origin" slot-scope="{ row }" :data="row.origin" />
          <div slot="action" slot-scope="{ index }">
            <fm-btn @click="delMedichine(index)" size="small">删除</fm-btn>
          </div>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import TableMixins from './mixins'

export default {
  mixins: [TableMixins],
  computed: {
    columns () {
      return [
        { title: '序号', type: 'index', width: 70, fixed: 'left' },
        { title: '药品/当量/净量', key: 'mdinfo', slot: 'mdinfo', fixed: 'left', minWidth: 185 },
        { title: '净重', key: 'weight', slot: 'weight', minWidth: 100 },
        { title: '当量', key: 'equivalent', slot: 'equivalent', minWidth: 140 },
        { title: '数量', key: 'volume', slot: 'volume', minWidth: 100 },
        { title: '单价（元）', key: 'price', minWidth: 120 },
        { title: '金额（元）', key: 'amount', slot: 'amount', minWidth: 120 },
        { title: '来源', key: 'origin', fixed: 'right', slot: 'origin', width: 100 },
        { title: '操作', key: 'action', slot: 'action', fixed: 'right', width: 70 }
      ]
    }
  }
}
</script>
