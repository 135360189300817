<style scoped lang="less">
  .prescription {
    background-color: #EEE;
  }
</style>

<template>
  <div class="prescription">
    <Prescription />
  </div>
</template>

<script>
import Prescription from '@/views/patients/details/prescription'
export default {
  components: { Prescription }
}
</script>
