<style lang="less" scoped>
  @import './styles.less';
</style>

<template>
  <div class="disease-item" :class="{active: active}">
    <div class="title">
      <div>
        <span class="name">{{num ? (num + '、') : ''}}{{data.name}}</span>
        <span class="type">西医病症</span>
      </div>
      <span class="btn" @click="$emit('action', { type: 'chinese', data })">中医对症({{diseaseNum ? diseaseNum : '无'}})</span>
    </div>
    <div class="details">
      <div class="field">
        <span>症状：</span>
        <div class="tags">
          <span v-for="item in items" :class="{active: highlighted.includes(item)}" :key="item">{{item}}</span>
        </div>
      </div>
    </div>
    <div class="btns">
      <div @click="$emit('action', { type: 'details', data })">查看病症解说</div>
      <div @click="$emit('action', { type: 'plan', data })">治疗方案</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null },
    num: { type: Number, default: null },
    highlighted: { type: Array, default: () => ([]) },
    active: { type: Boolean, default: false }
  },
  computed: {
    diseaseNum () {
      return this.data ? (this.data.relationDiseaseIds || '').split(',').filter(v => v).length : null
    },
    items () {
      return (this.data ? (this.data.symptom ? this.data.symptom.split(/[,，]/) : []) : []).filter(v => v)
    }
  }
}
</script>
