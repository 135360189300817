<style scoped lang="less">
  .group {
    border: 1px solid #dedede;
    padding: 5px;
    transition: all .3s;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.active {
      border-color: #2E6BE5;
    }
    .title {
    }
    & + .group {
      margin-top: 20px;
    }
  }
  .group-table {
    flex: 1;
    height: 0;
    position: relative;
  }
  .md-name {
    font-size: 14px;
    color: #606266;
  }
  .md-type {
    color: #9ea7b4;
    font-size: 12px;
  }
</style>

<template>
  <!-- 袋装 -->
  <div>
    <div class="group" :class="{active: activeIndex === i}" v-for="(item, i) in groups" :key="i" @click="activeIndex = i">
      <div class="title">
        <fm-form inline>
          <fm-form-item label="总剂数">
            <fm-input-number :min="1" style="width: 100px" v-model="item.timesNum"></fm-input-number>
          </fm-form-item>
          <fm-form-item label="用药频率">
            <fm-select v-model="item.usageFrequency" @change="item.setUsageFrequency()" clearable filterable placeholder="请选择用药频率">
              <fm-option v-for="(t, i) in usageFrequency" :key="i" :value="t" :label="t"></fm-option>
            </fm-select>
          </fm-form-item>
        </fm-form>
      </div>
      <div class="group-table" ref="table">
        <Table :height="tableHeight" :columns="columns" :data="item.list" no-data-text="暂无数据">
          <div slot="index" slot-scope="{ index }">{{index + 1}}</div>
          <div slot="mdinfo" slot-scope="{ row }">
            <div class="md-name">
              {{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineName : row.medichine.name}}
              [{{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicinePacking : row.medichine.packing}}]
            </div>
            <div class="md-type">{{row.medichine.mainbodyMedicine ? row.medichine.mainbodyMedicine.medicineManufacturer : row.medichine.manufacturer}}</div>
          </div>
          <div slot="dose" slot-scope="{ row }">{{row.dose}} g</div>
          <div slot="equivalent" slot-scope="{ row, index }">
            <InputNumber :formatter="(v) => v + ' ' + row.medichine.equivalentUnit" :parser="(v) => Number(String(v).replace(/[^\d\.\-]/g, ''))" :min="0" size="small" style="flex: 1" :active-change="false" @on-change="value => equivalentChange(value, item, row, index)" v-model="row.equivalent" />
          </div>
          <div slot="amount" slot-scope="{ row }">{{row.amount}}</div>
          <fm-select slot="medicineUsage" slot-scope="{ row, index }" style="width: 100%;" @change="v => $set(item.list[index], 'medicineUsage', v)" v-model="row.medicineUsage" clearable filterable placeholder="请选择用法">
            <fm-option v-for="(t, i) in usage" :key="i" :value="t" :label="t"></fm-option>
          </fm-select>
          <origin slot="origin" slot-scope="{ row }" :data="row.origin" />
          <div slot="action" slot-scope="{ index }">
            <fm-btn @click="delMedichine(index)" size="small">删除</fm-btn>
          </div>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import TableMixins from './mixins'

export default {
  mixins: [TableMixins],
  computed: {
    columns () {
      return [
        { title: '序号', type: 'index', fixed: 'left', width: 70 },
        { title: '药品/规格', key: 'mdinfo', slot: 'mdinfo', fixed: 'left', minWidth: 185 },
        { title: '剂量', key: 'dose', slot: 'dose', width: 140 },
        { title: '当量', key: 'equivalent', slot: 'equivalent', minWidth: 140 },
        { title: '袋数', key: 'volume', minWidth: 100 },
        { title: '单价（元）', key: 'price', minWidth: 120 },
        { title: '金额（元）', key: 'amount', slot: 'amount', minWidth: 120 },
        { title: '用法', key: 'medicineUsage', slot: 'medicineUsage', minWidth: 150 },
        { title: '来源', key: 'origin', fixed: 'right', slot: 'origin', width: 100 },
        { title: '操作', slot: 'action', fixed: 'right', width: 70 }
      ]
    }
  }
}
</script>
