<style scoped></style>

<template>
  <div v-if="data">
    <span v-if="data.type === 'local'">诊所售药</span>
    <span v-else-if="data.type === 'share'">共享药房</span>
    <span v-else-if="data.type === 'prescription'">处方调用</span>
    <span v-else-if="data.type === 'prescription-share'">共享开处方</span>
    <span v-else-if="data.type === 'prescription-local'">药房开处方</span>
    <span v-else>其他</span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, default: null }
  }
}
</script>
